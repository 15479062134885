import { TestComponent 						} from './demo/view/test/test.component';
import { MapPricingComponent 				} from './demo/view/map_pricing/mappricing.component';
import { LandingComponent 					} from './demo/view/landing/landing.component';
import { Routes, RouterModule				} from '@angular/router';
import { ModuleWithProviders				} from '@angular/core';
import { CheckinsComponent 					} from './demo/view/checkins/checkins.component';
import { MapComponent 						} from './demo/view/map/map.component';
import { GroupsComponent 					} from './demo/view/groups/groups.component';
import { TransportsComponent 				} from './demo/view/transports/transports.component';
import { TransportsCalendarsComponent   	} from './demo/view/transportsCalendars/transportsCalendars.component';
import { HotelsComponent 					} from './demo/view/entities/hotels/hotels.component';
import { FlightsComponent 					} from './demo/view/entities/flights/flights.component';
import { VehiclesComponent 					} from './demo/view/entities/transports/vehicles.component';
import { TransportCompaniesComponent 		} from './demo/view/entities/transports/companies.component';
import { DriversComponent 					} from './demo/view/entities/transports/drivers.component';
import { MapCheckDistancesComponent 		} from './demo/view/mapconfig/mapcheckdistances/mapcheckdistances.component';
import { AuthGuard 							} from './demo/service/auth/auth.guard';
import { StaffComponent 					} from './demo/view/entities/staff/staff.component';
import { ProfileComponent 					} from './demo/view/profile/profile.component';
import { ProvidersComponent					} from './demo/view/entities/providers/providers.component';
import { TourContainerComponent        		} from './demo/view/tours/main/component';
import { ChartBookingsComponent         	} from './demo/view/statistics/bookings/bookings.component';
import { PickupsZonesComponent        		} from './demo/view/mapconfig/pickupsZones/pickupszones.component';
import { AdminContainerComponent 			} from './demo/view/admin/main/component';
import { ResellerContainerComponent 		} from './demo/view/reseller/main/component';
import { CompanyContainerComponent 			} from './demo/view/company/main/component';
import { CompanyTransporterComponent 		} from './demo/view/company/transporters/component';
import { CompanyProvidersComponent 			} from './demo/view/company/providers/component';
import { InvoicingCompanyComponent 			} from './demo/view/invoicing/company/component';
import { CompanyDriverComponent       		} from './demo/view/company/drivers/component';
import { DashboardComponent             	} from './demo/view/dashboard/component';
import { DashboardAggregatorComponent 		} from './demo/view/dashboard_aggregator/component';
import { CreateDestinationComponent     	} from './demo/view/destinations/create/create-destination.component';
import { CreateCompanyComponent         	} from './demo/view/company/create/create-company.component';
import { MapGeneratorDestinationsComponent 	} from './demo/view/mapconfig/generatorDestinations/mapGeneratorDestinations.component';
import { DashboardResellerComponent     	} from './demo/view/reseller/dashboard/component';
import { ManageTransportsComponent      	} from './demo/view/company/manage-transports/manage-transports.component';
import { ManageZonesComponent           	} from './demo/view/company/zones/manage-zones.component';
import { ManageListPricesComponent      	} from './demo/view/company/manage-list-prices/manage-list-prices.component';
import { ImporterComponent                  } from './demo/view/importer/importer.component';
import { BookingsComponent                  } from './demo/view/bookings/bookings.component';
import { ExternalsComponent 				} from './demo/view/externals/externals.component';

export const routes: Routes = [
	{path: '',			component: LandingComponent 				},
	{path: 'landing',   component: LandingComponent 				},
    {path: 'login',     component: LandingComponent 				},
    {path: 'services',  component: ExternalsComponent			    },
    {	path		: 	'dashboard', 
		component	: 	DashboardComponent,	 
		canActivate	:	[ AuthGuard ],	
		data 		: 	{ 
			roles 	: 	[ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ]
		}		
	}
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload' });
