import { EntityService	} from './entity.service';
import { Injectable 	} from '@angular/core';
import { HttpClient  	} from '@angular/common/http';
import { flights        } from './../view/bookings/data/flights';
import { CommonsService } from './commons.service';

var fuzz 				= require('fuzzball');

@Injectable()
export class FlightService {

    flights: any[]	= [];

    constructor( 
        private entityService   : EntityService, 
        private http            : HttpClient,
        private commons         : CommonsService
    ){
        this.loadFlights();
    }

    checkBooking($booking){
        return true;
    }

	async loadFlights() {
		let response;
		this.clearFlights();
		
        // const flightDataOrigin:string = "File";
		const flightDataOrigin:string = "database";
		// const flightDataOrigin:string = "internet";

		switch(flightDataOrigin){
			case "file"     :   this["flights"] = flights;

			default:
            case "database"	:	await this.entityService.loadEntity("flights");
						        this["flights"]	= this.entityService.get("flights");
                                // Add file items for testing
                                this["flights"]= [ ...this["flights"], ...flights];
								// this["flights"].forEach(item => this.setFlight(item));
								break;

			case "internet"	:	response		= await Promise.resolve(this.entityService.getComplexRequest("flightsBySkyScanner",{ destination:'pmi' }));
								this["flights"]	= 	[ 	...response.arrivals.map	(item=>{item.direction="arrival"; 	return item;}), 
														...response.departures.map	(item=>{item.direction="departure";	return item;}) 
													];
								break;
		}

        // console.log('list flights', this.flights);
        return this.flights;
    }

    getFlights()       	{ return this.flights; 									}
    setFlight($flight)	{ this.flights.push($flight); 							}
    clearFlights() 		{ this.flights = [];                                  	}

    /**
     * check current flight against database flights
     * @param $flight 
     * @returns 
     */
    checkFlight($flight){
        $flight         = this.normalizeFlight($flight);
        let flightCheck = (this.flights||[]).some(item=>item.flightNumber===$flight);
        // CHeck for aliases
        if(undefined!==this.commons.pageInfo.flights_aliases){
            flightCheck = flightCheck || ( undefined!=this.commons.pageInfo.flights_aliases[$flight] );
        }
        return {    
            error       : !flightCheck, 
            type        : !flightCheck ? '_BAD_FLIGHT'  : null, 
            errorCode   : !flightCheck ? '57'           : null
        };
    }

    private normalizeFlight($flight){ 
        return ($flight||"")
                .replaceAll('\-','')
                .replaceAll('\ ','')
                .toUpperCase();        
    }

    fixFlight(flight){
        console.log('flight to check', flight);

        let possibleFlights =   this.flights.filter( el => fuzz.ratio(flight,el.code) > 60)
        console.log(possibleFlights);
    }
}
