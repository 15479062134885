export let servicesCols = [
    {
        field		: 'name',
        header		: '_GROUP',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'vehicle',
        header		: '_VEHICLE',
        width		: '200px',
        align       : 'center',
        // type        : 'vehicle',
        disabled	: false
    },
    {
        field		: 'transporter',
        header		: '_TRANSPORTER',
        width		: '200px',
        align       : 'center',
        type        : 'vehicle',
        disabled	: false
    },    
    {
        field		: 'transporter_status',
        header		: '_TRANSPORTER_STATUS',
        width		: '200px',
        align       : 'center',
        disabled	: false,
        translate   : true
    },
    {
        field		: 'fomento_id',
        header		: '_FOMENTO_ID',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'fomento_status',
        header		: '_FOMENTO_STATUS',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'time',
        header		: '_TIME',
        width		: '150px',
        align       : 'center',
        disabled	: false
    },
    ,{
        field		: 'type',
        header		: '_TYPE',
        width		: '80px',
        align       : 'center',
        disabled	: false
    },
    ,{
        field		: 'direction',
        header		: '_DIRECTION',
        width		: '80px',
        align       : 'center',
        disabled	: false
    },
    // {
    //     field		: 'driver',
    //     header		: '_DRIVER',
    //     width		: '80px',
    //     align       : 'center',
    //     disabled	: false
    // },
    {
        field		: 'pax',
        header		: 'Pax',
        width		: '50px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    // {
    //     field		: 'pickupDateTime',
    //     header		: '_PICKUP',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false
    // },
    {
        field		: 'area',
        header		: '_AREA',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'zone',
        header		: '_ZONE',
        width		: '150px',
        align       : 'center',
        disabled	: false
    },
    // {
    //     field		: 'address',
    //     header		: 'Address',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false
    // },
];
