/**
 * {'id'						:4187,
 * 	'reference'					:'E1250395',
 *	'date'						:'20180814',
 * 	'status'					:'OR',
 * 	'company'					:'RH',
 * 	'customer'					:'Mr Saangberg John Olof',
 * 	'bookingDate'				:'11\/08\/2018',
 * 	'phone'						:'+46707134617',
 * 	'vehicles'					:'1',
 * 	'vehicle'					:'Private transfer (1 -4 people)',
 * 	'transferType'				:'RETURN',
 * 	'location'					:'Fontanellas Playa Aparthotel',
 * 	'addressInfo'				:'Caravella s\/n, Can Pastilla',
 * 	'pax'						:'3',
 * 	'adults'					:'3',
 * 	'children'					:'0',
 * 	'infants'					:'0',
 * 	'price'						:'37.08',
 * 	'verified'					:'no',
 * 	'arrival_StartingPointType'	:'AP',
 * 	'arrival_From'				:'Majorca (Palma de Mallorca Airport) (PMI)',
 * 	'arrival_To'				:'Can Pastilla',
 * 	'arrival_Date'				:'16\/02\/2019',
 * 	'arrival_Time'				:'16:10',
 * 	'arrival_GatewayFrom'		:'ARN',
 * 	'arrival_GatewayTo'			:'ARN',
 * 	'arrival_GatewayInfo'		:'D86653',
 * 	'arrival_PickupDate'		:'',
 * 	'arrival_PickupTime'		:'',
 **/
export let bookingsCols = [
    {
        field		: 'error',
        header		: '_ERROR',
		width    	: '100px',
        disabled	: true
    },
    {
        field		: 'id',
        header		: '_ID',
        width		: '50px',
        disabled	: true
    },
    {
        field		: 'group',
        header		: '_GROUP',
        width		: '200px',
        disabled	: true,
        align  		: 'center'
    },
    {
        field		: 'reference',
        header		: '_REFERENCE',
        width		: '200px',
        disabled	: false,
        align  		: 'center'
    },
    {
        field		: 'orderref',
        header		: '_ORDERREF',
        width		: '200px',
        disabled	: false,
        align  		: 'center'
    },
    {
        field		: 'shared',
        header		: '_SHARED',
        width		: '120px',
        disabled	: true,
    },
    {
        field		: 'status',
        header		: '_STATUS',
        width		: '100px',
        disabled	: false
    },
    {
        field		: 'verified',
        header		: '_VERIFIED_COL',
        width		: '80px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'company',
        header		: '_COMPANY',
        width		: '100px',
        disabled	: true,
        newBlocked	: true,
        type        : 'image',
        renderer    : 'company'
    },
    // {
    //     field		: 'request',
    //     header		: '_REQUEST',
    //     width		: '80px',
    //     disabled	: false,
    //     editable	: false,
    //     align		: 'center'
    // },
    {
        field		: 'pax',
        header		: '_PAX',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
      {
        field		: 'children',
        header		: '_CHILDREN',
        width		: '80px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'infants',
        header		: '_INFANTS',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'customer',
        header		: '_TREATMENT',
        width		: '80px',
        disabled	: true,
        editable	: false,
        renderer	: 'treatment',
        align		: 'right'
    },
    {
        field		: 'customer',
        header		: '_CUSTOMER',
        width		: '300px',
        disabled	: false,
        editable	: false,
        renderer	: 'customer_full',
        align		: 'left'
    },
    {
        field		: 'pax',
        header		: '_PAX',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'price',
        header		: '_PRICE',
        width		: '100px',
        disabled	: true,
        editable	: false,
        renderer	: 'price',
        align		: 'right'
    },
    {
        field		: 'transferType',
        header		: '_TRANSFER_TYPE',
        width		: '100px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'vehicle',
        header		: '_VEHICLE',
        width		: '250px',
        disabled	: true,
		editable	: false,
		align		: 'center'
    },
    {
        field		: 'price',
        header		: '_PRICE',
        width		: '150px',
        disabled	: true,
		editable	: false,
		align		: 'center'
    },
    {
        field		: 'computed_price',
        header		: '_TOTAL_COMPUTED_PRICE',
        width		: '150px',
        disabled	: true,
		editable	: false,
		align		: 'center'
    }, 
    {
        both        : true,
        fields		: ['arrival_GatewayInfo','departure_GatewayInfo'],
        header		: '_FLIGHT',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center',
        renderer    : 'flight'
    },
    {
        both        : true,
        fields		: ['arrival_Date','departure_Date'],
        header		: '_DATE',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        both        : true,
        fields		: ['arrival_Time','departure_Time'],
        header		: '_HOUR',
        width		: '80px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'pickup',
        header		: '_PICKUP',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'direction_label',
        header		: '_DIRECTION',
        width		: '150px',
        disabled	: false,
        editable	: false,
        translate   : true,
        align		: 'center'
    },    
    {
        field		: 'zone',
        header		: '_ZONE',
        width		: '100px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },     
    {
        field		: 'area',
        header		: '_AREA',
        width		: '300px',
        disabled	: false,
        editable	: false,
        renderer	: 'area_inner',
        align		: 'center'
    },
    {
        field		: 'location',
        header		: '_ACCOMMODATION',
        width		: '300px',
        disabled	: false,
        editable	: false,
        _renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'addressInfo',
        header		: '_ADDRESS',
        width		: '300px',
        disabled	: true,
        editable	: false,
        _renderer	: 'arrival',
        align		: 'center'
    },

    {
        field		: 'arrival_Zone',
        header		: '_ZONE',
        width		: '100px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'extras',
        header		: '_EXTRAS',
        width		: '500px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'arrival_To',
        header		: '_AREA_TO',
        width		: '300px',
        disabled	: true,
        editable	: true,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'arrival_From',
        header		: '_AREA_FROM',
        width		: '300px',
        disabled	: true,
        editable	: false,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'arrival_GatewayTo',
        header		: '_ARRIVAL_ORIGIN',
        width		: '200px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'arrival_GatewayInfo',
        header		: '_ARRIVAL_FLIGHT',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center',
        renderer    : 'flight'
    },
    {
        field		: 'arrival_Date',
        header		: '_ARRIVAL_DATE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'arrival_Time',
        // header		: '_ARRIVAL_TIME',
        header		: '_HOUR',
        width		: '80px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'arrival_DriverNote',
        header		: '_ARRIVAL_DRIVER_NOTE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'arrivale_BookingNote',
        header		: '_ARRIVAL_BOOKING_NOTE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_To',
        header		: '_DEPARTURE_TO',
        width		: '300px',
        disabled	: true,
        editable	: false,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'departure_From',
        header		: '_DEPARTURE_FROM',
        width		: '300px',
        disabled	: true,
        editable	: false,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'departure_GatewayTo',
        header		: '_DEPARTURE_ORIGIN',
        width		: '200px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'departure_Zone',
        header		: '_ZONE',
        width		: '100px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_GatewayInfo',
        header		: '_DEPARTURE_FLIGHT',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center',
        renderer    : 'flight'
    },
    {
        field		: 'departure_Date',
        header		: '_DEPARTURE_DATE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_Time',
        // header		: '_DEPARTURE_TIME',
        header		: '_HOUR',
        width		: '100px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_DriverNote',
        header		: '_DEPARTURE_DRIVER_NOTE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_BookingNote',
        header		: '_DEPARTURE_BOOKING_NOTE',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    }
];
