import { AgmMarkerCluster 		} from '@agm/js-marker-clusterer';
import { MapService				} from 'src/app/demo/service/mapservice';
import { EntityService 			} from '../../demo/service/entity.service';
import { CommonsService 		} from '../../demo/service/commons.service';
import { 	Component, 
			Input, 
			Output, 
			EventEmitter,      
			OnInit,
			ViewChild,
			NgZone,
			ElementRef,
			PLATFORM_ID, 						
			Inject,             	
			HostListener		} from '@angular/core';
import { 	MapsAPILoader, 
			AgmCircle,
			AgmMap 				} from '@agm/core';
import { LodgingService       	} from 'src/app/demo/service/lodging/lodging.service';
import { FlightService 			} from 'src/app/demo/service/flight.service';
import { CheckService 			} from 'src/app/demo/service/database/check.service';
import { SimpleFirebaseService } from 'src/app/demo/service/database/simplefirebase.service';
// import { GoogleService 			} from 'src/app/demo/service/google/google.service';
  
  // declare var require: any
//   var fuzz 	= require('fuzzball');
//   declare var MarkerClusterer:any;
declare const google: any;

@Component({
  selector    	: 'app-booking-expander',
  templateUrl 	: './booking-expander.component.html',
  styleUrls		: ['./booking-expander.component.scss']
})
export class BookingExpanderComponent implements OnInit 
{
	// @HostListener('document:keydown', ['$event'])
    // keypress(e: KeyboardEvent) { this.keypressed(e.key); }

	@ViewChild('mainTable') 							mainTable 			: any;
	@ViewChild("searchAddress")  						searchOrigin		: ElementRef;
	@ViewChild('circle', 	{ read: AgmCircle       }) 	circle				: AgmCircle;
	@ViewChild('agmCluster',{ read: AgmMarkerCluster})	agmCluster			: any;
	// @ViewChild('map',     	{ read : AgmMap         })	map					: AgmMap;
	  
	@ViewChild('streetviewMap')     					streetviewMap   	: any;
	@ViewChild('streetviewPano')    					streetviewPano  	: any;
	@ViewChild('searchLodging')    						searchLodging   	: ElementRef;
	@ViewChild('dropDownArea')      					dropDownArea    	: any;
	@ViewChild('inputFilterLodging')					inputFilterLodging 	: any;

	@Input()	tab				: string;
	@Input()	rowData			: any	= {};
	@Input()	item			: any	= {};
	@Input()	col				: any	= {};

	@Output() 	emiterOption	: EventEmitter<any> = new EventEmitter();

	mapInstance	: any;
	pageInfo 	: any = {
		lodgings				: { 
			data			: [], 
			google_search	: { show: false }
		},
		checks					: {},
		place                   : {},
		tourinia_location       : '',
		tourinia_locations      : [],
		selectedFilter          : 'area',
		rangeDistance           : 200,
		indexTabView			: 0,
		displayRadius			: false,
		displayNearbyLodgings   : false,
		displayAddressMarker	: true,
		tableLodgings           : [],
		lodgingSelected         : {},
		addressSelected 		: {},
		nearbyLodgingSelected	: null,
		lodgingsSelecteds       : [],
		lodgingsInsideCircle    : [],
		lodgingsFilteredByArea  : [],
		draggableCircle         : true,
		listClusters            : [],
		inputSearchLodging      : null,
		toggleMapView			: { items: [], selected: [] },
		iconLodging : {
			url: 'assets/demo/images/maps/hotelMap.png',
			scaledSize: {
			width: 40,
			height: 50
			}
		},
		iconAddress : {
			url: 'http://www.gentechusa.com/wp-content/uploads/2015/09/marker.png',
			scaledSize: {
			width: 40,
			height: 50
			}
		}
	};

	// lodgings              :   any     = {};
	// zoom                :   number  = 15;
	// lat                 :   number  = 39.7714543;
	// lng                 :   number  = 3.1455784 ;
	
	constructor(private commons 		: CommonsService, 
				private entityService 	: EntityService,
				private mapsAPILoader	: MapsAPILoader,
				private ngZone			: NgZone,
				private mapCtrl         : MapService,
				private lodgingService  : LodgingService,
				private flightService	: FlightService,
				private checkService	: CheckService,
				// private googleService	: GoogleService,
				private firebaseService : SimpleFirebaseService,
				@Inject(PLATFORM_ID) private platformId: Object
	){}

	async ngOnInit(){
	}

	ngOnChanges(data) 				{
	}

	ngAfterViewInit()				{
		this.listenGoogleMapDirections(); // to call the autocompletes
	}

	keypressed($key){
		switch($key){
			case 'Enter'	: this.doEnter();		break;
			case 'F2'		: this.doF2(); 			break;
			case 'Escape'	: this.doEscape();		break;
		}
	}


	doF2(){
		if(!this.pageInfo.lodgings.google_search.show){
			this.doAction("search",{ type:'google_lodgings', action: "show" });
		}
	}

	doEscape(){
		if(this.pageInfo.lodgings.google_search.show){
			this.doAction("search",{ type:'google_lodgings', action: "hide" });
		}
	}

	doEnter(){
		if(this.pageInfo.lodgings.google_search.show){
			this.doAction("search",{ type:'google_lodgings', action: "search" });
		}
	}

	listenGoogleMapDirections()		{
		this.mapsAPILoader.load().then(() => {
			// this.commons.generateToast("_inFO","LOADED GOOGLE MAP","info");
		});
	}	

    getPlacePredictions(query: string): Promise<any> {		
        let autocompleteSrv = new google.maps.places.AutocompleteService();
        return new Promise((resolve, reject) => {
            autocompleteSrv.getPlacePredictions({
                // types: ['geocode','establishment'],
                input: query,
                // componentRestrictions: { country: 'AR' }
            }, function (predictions, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(predictions);
                } else {
                    reject(status);
                }
            });
        });
    }   

	getDetailsFromPlace(place_id) : Promise <any>{
        let placeInfo   =   new google.maps.places.PlacesService(document.createElement('div')); // createElement is a hack and actually it works 🤣
        return new Promise((resolve,reject)=>{
            placeInfo.getDetails(   {   placeId : place_id,
                                        fields  : [ 'place_id',
                                                    'name', 
                                                    'address_component', 
                                                    'type', 
                                                    'formatted_address', 
                                                    'geometry',
                                                    'international_phone_number',
                                                    'photos',
                                                    'vicinity',
                                                    'website']
            }, (place,status) =>{
                if(status == google.maps.places.PlacesServiceStatus.OK && place){
                    resolve({success : true, status : status, data : place});
                }else{
                    resolve({success : false, data : place, status : status});
                }
            })
        })
    }

	processGoogleLodging($info){
		this.ngZone.runOutsideAngular(() => {
			switch(this.pageInfo.searchItem.step){
				case "hotel"		:
					this.pageInfo.place = $info.autocomplete.getPlace();
					// console.log('PLACED ITEM ON HOTEL', this.pageInfo.place);
					// if (this.pageInfo.place.geometry === undefined || this.pageInfo.place.geometry === null) {
					//     return this.pasteTitleLodging({step:'address',value:this.rowData.addressInfo});
					// }
					// this.mountLodgingFromGoogle(this.pageInfo.place);
					break;
			  }
		  })
	}

	/**
	 * Determine if set to disabled the column
	 * @param booking booking
	 * @param col col from form => INFO | ARRIVAL | DEPARTURE
	 */
	checkIfDisableCol(booking, col) 	{	
		if (col.type == 'info' || !booking) { return false; }
		switch (true) {
			default										: return false;
			case (booking.direction == 'both'		)	: return false;
			case (booking.direction == 'arrival'	)	: return !(booking.direction == col.type);
			case (booking.direction == 'departure'	)	: return !(booking.direction == col.type);
		}
	}

	private getCountInfo($booking, $type) 		{	
		return $booking[$type] ? $booking[$type].length : 0;	
	}

	getInfoClass(booking, field) 				{	
		if (!booking.errors && !booking.warnings) { return {}; }
		let fieldError = (booking.errors || []).find(el => el.field == field);
		if (fieldError) { return fieldError }
		let fieldWarning = (booking.warnings || []).find(el => el.field == field);
		return fieldWarning ? fieldWarning : {};
	}

	getColorIconRow(rowData) 			{	
		let warnings = this.getCountInfo(rowData, 'warnings');
		let errors = this.getCountInfo(rowData, 'errors');
		switch (true) {
			case (errors > 0): return 'tomato';
			case (warnings > 0 && errors == 0): return 'yellow';
			default: return 'green';
		}
	}

	onWrittingLodging($event)   {   
		console.log(document.getElementsByClassName('pac-item-query'));         
	}

	// mountLodgingFromGoogle(place){
	// 	this.pageInfo.lodgingSelected               =   this.lodgingService.mountLodgingFromGoogle(place);
	// 	this.lat	                            	=	place.geometry.location.lat();
	// 	this.lng	                            	=	place.geometry.location.lng();
	// 	this.pageInfo.displayNearbyLodgings     	=   false;
		
	// 	this.searchOrigin.nativeElement.value   	=   place.formatted_address;
	// 	this.pageInfo.indexTabView              	=   1;        
	// 	if(this.dropDownArea.selectedOption){
	// 		this.pageInfo.lodgingSelected.area			=	this.dropDownArea.selectedOption.label ? this.dropDownArea.selectedOption.label : null; 
	// 	}else{
	// 		this.pageInfo.lodgingSelected.area          =   null;
	// 	}
	// }

	// mountLodgingFromDB(place){
	// 	this.searchLodging.nativeElement.value		=	place.name;
	// 	this.searchOrigin.nativeElement.value   	=   place.address;
	// 	this.pageInfo.lodgingSelected.municipality	=	this.pageInfo.lodgingSelected.zipcode + ", " + this.pageInfo.lodgingSelected.area;			
	// 	// this.dropDownArea.selectedOption			=	this.findArea({label : this.pageInfo.lodgingSelected.area});
	// }
	
	/**
	 * 
	 * @param place
	 */
	// mountAddressFromGoogle(place){
	// 	this.pageInfo.lodgingSelected				=	{};
	// 	this.pageInfo.addressSelected				=	place;
	// 	this.pageInfo.addressSelected.latitude		=	place.geometry.location.lat();
	// 	this.pageInfo.addressSelected.longitude		=	place.geometry.location.lng();
	// 	this.lat									=	place.geometry.location.lat();
	// 	this.lng									=	place.geometry.location.lng();
	// }

	getPositionCircle(){
		if(!this.pageInfo.lodgingSelected && !this.pageInfo.addressSelected) return {};
		// if((this.pageInfo.lodgingSelected == {}) && (this.pageInfo.addressSelected=={})) return {};
		return this.pageInfo.lodgingSelected.latitude 	? 	{lat : this.pageInfo.lodgingSelected.latitude,lng	: this.pageInfo.lodgingSelected.longitude}
														: 	{lat : this.pageInfo.addressSelected.latitude,lng	: this.pageInfo.addressSelected.longitude}
	}
	
	getRendererType($type) 				{
		switch ($type) {
			case 'area_inner'		: return (type, col, items) => this.innerAreaRenderer	(this, type, col, items);
			case 'company'			: return (type, col, items) => this.companyRenderer		(this, type, col, items);
			case 'treatment'		: return (type, col, items) => this.treatmentRenderer	(this, type, col, items);
			case 'customer'			: return (type, col, items) => this.customerRenderer	(this, type, col, items, 'retail');
			case 'customer_full'	: return (type, col, items) => this.customerRenderer	(this, type, col, items, 'full');
			case 'price'			: return (type, col, items) => this.priceRenderer		(this, type, col, items);
			case 'arrival'			: return (type, col, items) => this.arrivalRenderer		(this, type, col, items);
			case 'departure'		: return (type, col, items) => this.departureRenderer	(this, type, col, items);
			case 'pax'				: return (type, col, items) => this.paxRenderer			(this, type, col, items);
			case 'flight'			: return (type, col, items) => this.flightRenderer		(this, type, col, items);
		}
	}

	getRenderer($type, $col, $items, $info?) 	{
		return $col.renderer
			// ? $col.renderer($type, $col, $items)
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items, $info);
	}

	defaultRenderer($type, $col, $items, $info?){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				if(undefined!=$info){
					if($info["both"]){
						return $items[$col.fields[$info.index]];
					}
				}
				return $items[$col.field];
		}
	}

	defaultStyleRenderer($col) 			{
		return {
			'width'		: $col.width ? $col.width : '',
			'text-align': $col.align ? $col.align : ''
		};
	}

	innerAreaRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return { ...this.defaultStyleRenderer($col) };
			case 'content'	: return $items[$col.field];
		}
	}

	flightRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return {
				...this.defaultStyleRenderer($col),
				'color'			: !this.flightService.checkFlight($items[$col.field]).error ? 'gray' : 'red',
				'font-weight'	: 700
			};
			case 'expander'	: return {
				'color'			: !this.flightService.checkFlight($items[$col.field]).error ? 'gray' : 'red',
				'font-weight'	: 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	/**
	 * check if entity has specific value
	 * @param $entity
	 * @param $value
	 */
	 checkEntity($entity, $value) 				{	return true;	}

	 checkFlight(item) 							{	
		alert("Checking Flight from " + item.source);
		// this.messageService.add({
		// 	severity: 'success',
		// 	summary: 'Message',
		// 	detail: "Checking Flight from " + item.source
		// });
	}

	arrivalRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return {
				...this.defaultStyleRenderer($col),
				'color': this.checkEntity('areasList', $items[$col.field]) ? 'green' : 'red',
				'font-weight': 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	departureRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header': return this.defaultStyleRenderer($col);
			case 'style': return {
				...this.defaultStyleRenderer($col),
				'color': this.checkEntity('areasList', $items[$col.field]) ? 'green' : 'red',
				'font-weight': 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	companyRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'class'	: return "company-logo";
			case 'content'	: return '/assets/layout/icons/providers/' + $items[$col.field] + '.png';
		}
	}

	treatmentRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return $me.toCamelCase($items[$col.field].match(/\w+/)[0]);
		}
	}

	customerRenderer($me, $type, $col, $items, $flavour = 'retail') {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return ( $items[$col.field].match(/\w+/g) || [] )
										.filter((item, index) => index > ($flavour === 'full' ? -1 : 0))
										.map(item => this.toCamelCase(item))
										.join(' ')
										;
		}
	}

	priceRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return $me.floatRenderer($items[$col.field], 2) + ' €';
		}
	}

	paxRenderer($me, $type, $col, $items) 	{
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			// tslint:disable-next-line:radix
			// case 'content': return parseInt($items[$col.field]);
			case 'content'	: return $items['pax'] + ' pax (' + $items['adults'] + '/' + $items['children'] + '/' + $items['infants'] + ')';
		}
	}

	toCamelCase($value) 					{ return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase(); }
	floatRenderer($value, $precision = 2) 	{ return parseFloat($value).toFixed($precision); 											}


	getInfo($type,$info)		{
		switch($type){
			case "google_lodgings":
				switch($info.type){
					case "is_selected":
						return $info.item==this.pageInfo.lodgings.google_search.selected;

					case "buttons": 
						const lodging_search_buttons = [
							{ name: 'create_lodging_alias', label: "_CREATE_LODGING_ALIAS", icon: "copy" 	},
							{ name: 'notify_wrong_lodging', label: "_NOTIFY_WRONG_LODGING", icon: "times" 	},
							{ name: 'close_lodging_search', label: "_CLOSE", 				icon: "times" 	}							
						];
						return lodging_search_buttons;

					// id		: data.place_id,
					// name	: data.name,
					// address	: data[1].long_name,
					// number	: data[0].long_name,
					// area	: data[2].long_name,
					// country	: data[5].long_name,
					case "headers"	: return [
						// { label: "_ID"			, field: "id"			, flex: 1	},
						{ label: "_NAME"		, field: "name"			, flex: 5	},
						{ label: "_ADDRESS"		, field: "address"		, flex: 5	},
						{ label: "_NUMBER"		, field: "number"		, flex: 1	},
						{ label: "_AREA"		, field: "area"			, flex: 3	},
						{ label: "_COUNTRY" 	, field: "country"		, flex: 3 	}						
					];
				}
				break;

			case "flight"		:
				switch($info.type){
					case "has_errors":	return $info.rowData.errors.some(error=>error==$info.item.check_field);
				}
				break;


			case "checks"		:	
				switch($info.type){
					case "check"			:	let response = this.checkService.checkField({
													verify	: false,
													checks	: this.pageInfo.checks,
													type	: "check",
													item	: $info.item,
													data	: $info.rowData
												});
												this.pageInfo.checks = response;
												break;

					case "displayFixAction"	:	this.checkService.displayFixAction($info.item,$info.rowData); break;
				}
				break;
		}
	}

	checkIfEditable($item,$row)	{	return $row["newItem"]?!($item["newBlocked"]?true:false):$item["editable"];			}

	doSearch($item, $event) 			{
		switch($item.field){
			case "arrival_municipality_dropdown":
			case "departure_municipality_dropdown":
				this.pageInfo.results = (this.commons.userInfo.tourinia_areas||[])
					.filter(area=>{
						if(undefined==$item.value){ return true; }
						let item = (undefined!=$item.value && undefined==$item.value.id)
									?$item.value
									:$item.value.id;
						if(undefined==item|| item ==""){ return true; }
						return (area.toLowerCase()).includes(item.toLowerCase());
					})
					.map(area=>{
							let area_item = {
							id		: area,
							name	: area,
							value	: area,
							label	: area
						};
						return area_item;
					})
					.sort((a,b)=>a.label>=b.label?1:-1);
				break;
		}

		switch($item.entityType){
			case "data"		:
				// if(undefined == $item.entityList){
				// 	this.pageInfo.results = [];
				// 	return false;
				// }
				// if(undefined == this[$item.entityList]){
				// 	this.pageInfo.results = [];
				// 	return false;
				// }
				// this.results = (this[$item.entityList].data||[]).filter(item => {
				// 	return (item.label||"_ALL_RESULTS").includes($event.query);
				// });
				break;
			default			:
			case "entity"	:
				// if (undefined == $item.entityList) { this.results = []; return false; }
				// this.pageInfo.results = (this.commons.getEntity($item.entityList)||[]).filter(item => item.includes($event.query));
				break;
		}
	}

	// this.doAction("search",{ type:' google_lodgings', action: "show" });
	private doGoogleLodgingsAction($info){
		switch($info.action){
			case "show"		: 	
				this.pageInfo.lodgings.google_search.show 	= true; 
				this.pageInfo.show_shadow					= true;

				// Copy hotel into input
				switch(this.tab){
					case "arrival":
						this.pageInfo.lodgings.google_search.content = this.rowData.arrival_Location;
						break;
					case "departure":
						this.pageInfo.lodgings.google_search.content = this.rowData.departure_Location;
						break;
				}
				break;
			case "hide"		:
				this.pageInfo.lodgings.google_search.show 	= false;						
				this.pageInfo.show_shadow					= false;					
				break;
			case "exec"		:
			case "search"	:
				this.commons.generateToast("_EXEC","exec","info");
				break;
		}
	}

	private _createLodgingAlias(){
		if(null==this.pageInfo.lodgingSelected || undefined==this.pageInfo.lodgingSelected){
			alert("Select a lodging");
			return false;
		}
		const lodgingSelected = JSON.parse(JSON.stringify(this.pageInfo.lodgingSelected));
		if(undefined==lodgingSelected){
			alert("Select a lodging");
			return false;
		}
		alert("Crearing alias for "+this.pageInfo.lodgingSelected+" with value "+this.rowData.location);
		this.emiterOption.emit({action: 'create_alias', lodging: lodgingSelected, alias: this.rowData.location});
	}

	async createLodgingAlias($info){
		this.commons.generateToast("_INFO","create_lodging_alias","info");
		
		const country 		= (this.commons.userInfo.currentCountry.country||"").toLowerCase();
		const destination	= this.commons.userInfo.currentDestination.id;
		const dmc			= this.commons.userInfo.currentDmc.id;

		if(undefined==country || undefined==destination || undefined==dmc){
			this.commons.generateToast("_ERROR","_NO_DESTINATION_INFO_FOUND","error");
			return false;
		}

		// const path = "countries/"+country+"/destinations/"+destination;
		const path = "dmcs/"+dmc+"/destinations/"+destination;
		let lodgings_aliases = this.commons.userInfo.currentDestination.lodgings_aliases || {};
		let google_lodgings = this.commons.userInfo.currentDestination.google_lodgings || [];

		if(undefined==lodgings_aliases[$info.current]){
			lodgings_aliases[($info.current||"").toLowerCase()] = $info.google.name;
		}

		if(google_lodgings.some(item=>item.name!=$info.google.name)){
			google_lodgings.push($info.google);
		}
		
		switch(this.rowData.direction){
			case "both"		:
				this.rowData.arrival_Location_Google 	= $info.google.name;
				this.rowData.arrival_Area_Google 		= $info.google.area;
				this.rowData.arrival_Address_Google		= $info.google.address;
				this.rowData.arrival_Latitude			= $info.google.latitude;
				this.rowData.arrival_longitude			= $info.google.longitude;
				this.rowData.arrival_Location_Google 	= $info.google.name;
				this.rowData.departure_Area_Google 		= $info.google.area;
				this.rowData.departure_Address_Google	= $info.google.address;
				this.rowData.departure_Latitude			= $info.google.latitude;
				this.rowData.departure_longitude		= $info.google.longitude;
				break;

			case "arrival"	:		
				this.rowData.arrival_Location_Google 	= $info.google.name;
				this.rowData.arrival_Area_Google 		= $info.google.area;
				this.rowData.arrival_Address_Google		= $info.google.address;
				this.rowData.arrival_Latitude			= $info.google.latitude;
				this.rowData.arrival_longitude			= $info.google.longitude;
				break;

			case "departure":
				this.rowData.arrival_Location_Google 	= $info.google.name;
				this.rowData.departure_Area_Google 		= $info.google.area;
				this.rowData.departure_Address_Google	= $info.google.address;
				this.rowData.departure_Latitude			= $info.google.latitude;
				this.rowData.departure_longitude		= $info.google.longitude;
				break;
		}

		await this.firebaseService.updateDoc(path,{ 
			lodgings_aliases 	: lodgings_aliases,
			google_lodgings		: google_lodgings
		});

		this.commons.userInfo.currentDestination.lodgings_aliases 	= lodgings_aliases;
		this.commons.userInfo.currentDestination.google_lodgings	= google_lodgings;
	}

	async doAction($type,$info){
		switch($type){
			case "button"				:
				switch($info.action){
					case "create_lodging_alias"		:
						let current;
						let selected = this.pageInfo.lodgings.google_search.selected;

						if(undefined==selected){
							this.commons.generateToast("_ERROR","_GOOGLE_LODGING_NOT_SELECTED","error");
							return false;
						}
						switch(this.rowData.direction){
							case "both"		:
							case "arrival"	: current = this.rowData.arrival_Location; 		break;
							case "departure": current = this.rowData.departure_Location; 	break;
						} 
						
						this.createLodgingAlias({
							current	: current,
							google 	: selected
						}); 
						
						this.doGoogleLodgingsAction({ action: "hide" });
						
						break;

					case "notify_wrong_lodging"	:
						this.commons.generateToast("_INFO","notificate_wrong_lodging","info");
						switch(this.rowData.direction){
							case "arrival"	:	this.rowData.arrival_wrongresort 	= true; break;
							case "departure":	this.rowData.departure_wrongresort	= true; break;
						}
						break;

					case "close_lodging_search"		: 
						// this.commons.generateToast("_INFO","close_lodging_search","info");
						this.doGoogleLodgingsAction({ action: "hide" });
						break;

					case "google_lodging_search": 
						this.doGoogleLodgingsAction({ action: "show" });
						break;

					case "google_lodging_close"	:
						this.doGoogleLodgingsAction({ action: "hide" });
						break;
				}	
				break;
				
			case "search"				:	
				switch($info.type){
					case "google_lodgings":	this.doGoogleLodgingsAction($info); break;
				}
				switch($info.action){				
					case "autocomplete"	:	this.doSearch($info.item,$info.event);	break;
				}
				break;

			case "google_lodgings"		:
				switch($info.action){
					case "select"		:
						this.pageInfo.lodgings.google_search.selected 	= $info.item; 
						break;
					case "close"		:
						this.pageInfo.lodgings.google_search.show		= false;
						this.pageInfo.show_shadow						= false;
						this.pageInfo.lodgings.google_search.results 	= [];
						this.pageInfo.lodgings.google_search.content	= undefined;
						break;

					case "search"		:	
						let content	= this.pageInfo.lodgings.google_search.content;
						if((content||"").length<3){ 
							return false; 
						}
						let items  	= await this.getPlacePredictions(content);
						let results = [];

						for(let item of items){
							let response = await this.getDetailsFromPlace(item.reference);
							console.log("INFO",response);
							if(response.success && response.data && response.data.address_components ){
								let data = response.data.address_components;
								let info = {
									id		: response.data.place_id,
									name	: response.data.name
								};
								if(undefined!==data[1]){ info["address"	] = data[1].long_name; }
								if(undefined!==data[0]){ info["number"	] = data[0].long_name; }
								if(undefined!==data[2]){ info["area"	] = data[2].long_name; }
								if(undefined!==data[5]){ info["country"	] = data[5].long_name; }
								results.push(info);
							}
						}

						switch(this.tab){
							case "arrival":
								this.pageInfo.lodgings.current	= {
									id		: 0,
									name	: this.rowData.arrival_Location,
									address	: this.rowData.arrival_AddressInfo,
									// area	: this.rowData.arrival_To,
									area	: this.rowData.arrival_municipality,
									number	: '',
									country : ''
								}
								break;
							case "departure":
								this.pageInfo.lodgings.current	= {
									id		: 0,
									name	: this.rowData.departure_Location,
									address	: this.rowData.departure_AddressInfo,
									// area	: this.rowData.departure_From,
									area	: this.rowData.departure_municipality,
									number	: '',
									country : ''
								}
								break;
						}

						this.pageInfo.lodgings.google_search.results = results;
						
						// this.googleService.getPlacePredictions(content)
						// .then(response=>{
						// 	this.pageInfo.lodgings.google_search.results = response.data; 
						// })
						// .catch(e=>{
						// 	this.commons.generateToast("_ERROR",e.reason,"error");
						// })
						break;
				}
				break;

			case "lodging": 
				switch($info.action){
					// case "copy"         :   this.pasteTitleLodging({ step: 'hotel', value: $info.value}); 
					// 						//this.pasteTitleLodging({ step: 'lodging', value: $info.value});
					// 						this.pageInfo.tourinia_location = $info.value;
					// 						break; 
					
					case "search"       :   
						// No results if entry length is small
						if((this.pageInfo.tourinia_location||"").length<3){
							this.pageInfo.tourinia_locations = [];
						}
						this.pageInfo.tourinia_locations = this.pageInfo.lodgings.filter(item=>
								item.name.toLowerCase().includes(this.pageInfo.tourinia_location.toLowerCase())
							).map(item=>{
								item.label  = item.name;
								return item;
							})
							.sort((a,b)=>a.label>b.label?1:-1);
						break;

					case "autocomplete" :   
						this.onWrittingLodging($info.event);
						break;
			}
		}
	}
}
