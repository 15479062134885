export const bookingsCols = [
    {
        field		: 'id',
        header		: '',
        width		: '50px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'provider_name',
        header		: '',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'imported',
        header		: '_IMPORTED',
        width		: '50px',
        align       : 'center',
        type        : 'imported',
        disabled	: true
    },
    {
        field		: 'group',
        header		: '_GROUP',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'vehicle',
        header		: '_VEHICLE',
        width		: '150px',
        align       : 'center',
        disabled	: false,
        editor      : 'input'
    },
    {
        field		: 'transporter',
        header		: '_TRANSPORTER',
        width		: '150px',
        align       : 'center',
        disabled	: false,
        _type        : 'dropdown',
        _entity      : 'transportersList',
        translate   : true
    },
    {
        field		: 'transporter_status',
        header		: '_TRANSPORTER_STATUS',
        width		: '150px',
        align       : 'center',
        disabled	: false,
        _type        : 'dropdown',
        _entity      : 'transportersList',
        translate   : true
    },
    {
        field		: 'reference',
        header		: '_REFERENCE',
        width		: '200px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'area',
        header		: '_AREA',
        width		: '300px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'zone',
        header		: '_ZONE',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'group_pax',
        header		: '_GROUP_PAX',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'pax',
        header		: '_PAX',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },    
    {
        field		: 'adults',
        header		: '_ADULTS',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'children',
        header		: '_CHILDREN',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'infants',
        header		: '_INFANTS',
        width		: '80px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'flight',
        header		: '_FLIGHT',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'time',
        header		: '_FLIGHT_TIME_HEADER',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'group_firstTime',
        header		: '_GROUP_FIRSTTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'departure_PickupTime',
        header		: '_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'group_Departure_PickupTime',
        header		: '_GROUP_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'generated_PickupTime',
        header		: '_GENERATED_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    // {
    //     field		: 'shared',
    //     header		: '_SHARED_SHORT',
    //     width		: '80px',
    //     disabled	: true,
    // },
    // {
    //     field		: 'direction',
    //     header		: '_DIRECTION_SHORT',
    //     width		: '100px',
    //     disabled	: false,
    //     editable	: false
    // },
    {
        field		: 'customer',
        header		: '_CUSTOMER',
        width		: '200px',
        align       : 'center',
        disabled	: true
    },
    // {
    //     field		: 'transporter',
    //     header		: '_TRANSPORTER',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false,
    //     _type        : 'dropdown',
    //     _entity      : 'transportersList',
    //     translate   : true
    // },
    // {
    //     field		: 'transporter_status',
    //     header		: '_TRANSPORTER_STATUS',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false,
    //     _type        : 'dropdown',
    //     _entity      : 'transportersList',
    //     translate   : true
    // },
    // {
    //     field		: 'vehicle',
    //     header		: '_VEHICLE',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false,
    //     editor      : 'input'
    // },
    {
        field		: 'plate',
        header		: '_PLATE',
        width		: '150px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'driver',
        header		: '_DRIVER',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'fomento_id',
        header		: '_FOMENTO_ID',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'fomento_status',
        header		: '_FOMENTO_STATUS',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'shared',
        header		: '_SHARED_SHORT',
        width		: '80px',
        disabled	: true,
    },
    {
        field		: 'direction',
        header		: '_DIRECTION_SHORT',
        width		: '100px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'date',
        header		: '_DATE',
        width		: '150px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'time',
        header		: '_FLIGHT_TIME_HEADER',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'group_firstTime',
        header		: '_GROUP_FIRSTTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'departure_PickupTime',
        header		: '_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'group_Departure_PickupTime',
        header		: '_GROUP_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'generated_PickupTime',
        header		: '_GENERATED_PICKUPTIME',
        width		: '100px',
        align       : 'center',
        disabled	: false
    }
    ,{
        field		: 'errors',
        header		: '_ERRORS',
        width		: '300px',
        align       : 'center',
        disabled	: false,
        translate   : true
    }
];
