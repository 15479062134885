export const steps	=	[
    {
        id		            :	0,
        active	            :	true,
        value               :   'dmcs',
        label	            :	'_LIST_DMCS',
        description         :   '_SELECT_OR_CREATE_DMC',
        actionValidateStep  :   'check_dmc',
        initFunction        :   'listDmcs',
        saveSingleStep      :   true,
        saveLabel           :   '_SAVE_INFORMATION',
    },
    {
        id		            :	1,
        active	            :	false,
        value               :   'destinations',
        initFunction        :   'getDestinationsFromDmc',
        label	            :	'_DESTINATIONS',
        description         :   '_SELECT_COUNTRY_AND_DESTINATION',
        directClick         :   'checkDmcSelected'
    },
    {
        id		            :	2,
        active	            :	false,
        initFunction        :   'loadStations',
        value               :   'config_stations',
        label	            :	'_STATIONS',
        description         :   '_SELECT_STATIONS_FROM_DESTINATION',
        directClick         :   'checkDestinationSelected',
        saveSingleStep      :   true,
        saveLabel           :   '_SAVE_STATIONS'
    },
    {
        id		            :	3,
        active	            :	false,
        value               :   'config_transport',
        label	         	:	'_TRANSPORT',
        directClick         :   'checkDestinationSelected',
        initFunction        :   'loadTransports',
        _actionValidateStep :   'check_zones',
        saveSingleStep      :   false,
    },
    {
        id		        	:	4,
        active	        	:	false,
        initFunction    	:   'loadProviders',
        value           	:   'config_providers',
        label	        	:	'_PROVIDERS',
        description     	:   '_SELECT_PROVIDER_FROM_DESTINATION',
        directClick     	:   'checkDmcSelected',
        saveSingleStep  	:   true,
        saveLabel       	:   '_SAVE_PROVIDERS'
    },
    {
        id		        	:	5,
        active	        	:	false,
        initFunction    	:   'loadListPrices',
        value           	:   'transport_list_prices',
        label	        	:	'_TRANSPORT_PRICING',
        directClick     	:   'checkAggregatorAssigned',
        saveSingleStep  	:   false,
        saveLabel       	:   '_SAVE_PROVIDERS'
    },
    {
        id		            :	6,
        active	            :	false,
        value               :   'config_zones',
        label	            :	'_ZONES',
        directClick         :   'checkAggregatorAssigned',
        initFunction        :   'loadMapZones',
        actionValidateStep  :   'check_zones',
        saveSingleStep      :   false,
    }
];

export const tutorialSteps = [
    {
        value           :   'dmcs',
        views           :   [
            {
                type    :   'list',
                template    :   {
                    title           :   '_MANAGEMENT_DMCS',
                    elements        :   [
                        {
                            type    :   'description',
                            text    :   '_EXPLICATION_CRUD_DMCS'
                        },
                        // {
                        //     type    :   'steps',
                        //     elements    :   [
                        //         { text : '_STEP_EXPLAIN_CREATE_DMC'},
                        //         { text : '_STEP_EXPLAIN_EDIT_DMC'}
                        //     ]
                        // },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   [
                                            { source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                                        ]
                        }
                    ]            
                }
            },
            {
                type    :   'management',
                template    :   {
                    title           :   '_CREATE_EDIT_DMC',
                    elements        :   [
                        {
                            type    :   'description',
                            text    :   '_EXPLICATION_CREATE_DMCS'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                { text : '_STEP_CREATE_DMC_INFO'},
                                { text : '_STEP_CREATE_DMC_MODULES'},
                                { text : '_STEP_CREATE_DMC_MULTIMEDIA'}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]            
                }
            }
        ],
    },
    {
        value           :   'destinations',
        views           :   [
            {
                type        :   'list',
                template    :   {
                    title   :   '_MANAGEMENT_DESTINATIONS',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_EXPLICATION_MANAGEMENT_DESTINATIONS'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                { text : '_STEP_DESTINATION_SELECT_COUNTRY'    	},
                                { text : '_STEP_DESTINATION_CREATE_DESTINATION'	},
                                { text : '_STEP_DESTINATION_EDIT_DESTINATION'	},
                                { text : '_STEP_DESTINATION_REMOVE_DESTINATION'	}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]
                }
            },
            {
                type        :   'select_destination',
                template    :   {
                    title   :   '_SELECT_DESTINATION',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_EXPLICATION_SELECT_DESTINATION'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                { text : '_STEP_DESTINATION_SELECT_COUNTRY'},
                                { text : '_STEP_DESTINATION_SELECT_TEMPLATE'},
                                { text : '_STEP_DESTINATION_CREATE_DESTINATION'}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   
                            [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]
                },   
            },
            {
                type        :   'create_destination',
                template    :   {
                    title   :   '_CREATE_DESTINATION',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_EXPLICATION_CREATE_DESTINATION'
                        },
                        {
                            type    :   'steps',
                            elements:   [
                                { text : '_MAP_CREATE_DESTINATION_STEP1'},
                                { text : '_MAP_CREATE_DESTINATION_STEP2'},
                                { text : '_MAP_CREATE_DESTINATION_STEP3'},
                                { text : '_MAP_CREATE_DESTINATION_STEP4'}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   
                            [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]
                }
            }
        ]
    },
    {
        value           :   'config_stations',
        views           :   [
            {
                type    :   'stations',
                template    :   {
                    title       :   '_MANAGEMENT_STATIONS',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_DESCRIPTION_MANAGEMENT_STATIONS'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                {
                                    text : '_EXPLICATION_STATION_TERMINAL'
                                },
                                {
                                    text : '_EXPLICATION_NO_STATIONS'
                                }
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   
                            [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]   
                }
            }
        ]
    },
    {
        value           :   'config_zones',
        views           :   [
            {
                type    :   'zones',
                template:   {
                    title   :   '_MANAGEMENT_ZONES',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_DESCRIPTION_MANAGEMENT_ZONES'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                {   text    :   '_PRESS_BUTTON_CREATE_ZONE'},
                                {   text    :   '_SELECT_DESIRED_AREAS'},
                                {   text    :   '_ZONE_SAVE_STEP'},
                                {   text    :   '_ZONE_FILL_REST_AREAS'},
                                {   text    :   '_EXPLICATION_REMOVE_ZONE'},
                                {   text    :   '_SKIPPEABLE_STEP'}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   
                            [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]
                }
            }
        ]
    },
    {
        value           :   'config_providers',
        views           :   [
            {
                type    :   'providers',
                template    :   {
                    title   :   '_MANAGEMENT_PROVIDERS',
                    elements    :   [
                        {
                            type    :   'description',
                            text    :   '_DESCRIPTION_MANAGEMENT_PROVIDERS'
                        },
                        {
                            type    :   'steps',
                            elements    :   [
                                { text  :   '_SELECT_PROVIDER_FROM_LIST'},
                                { text  :   '_SET_INFO_PROVIDER'},
                                { text  :   '_SET_SCHEDULER_TO_PROVIDER'},
                                { text  :   '_EXPLICATION_DELETE_SCHEDULER'}
                            ]
                        },
                        {
                            type    :   'multimedia',
                            subtype :   'gallery',
                            images  :   
                            [{ source : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXu7u7MzMzT09Pd3d3X19fs7OzOzs7Jycnh4eHn5+fq6urR0dHNzc3Z2dnk5OTf398rdJRGAAABzElEQVR4nO3ay3aqQBRAQRGkQYL+/99eY16CicAFbZpVNXZw9joNEsxuBwAAAAAAAAAAAAAAAAAAAAAAAKSizot9cT7GHuNp6iZk70JxiD3Kc1QffVd17GGe4XQTmGUb3OIh62hiz7O8vFsYTrEHWlzZLcyK2AMt7RB6hWXsiZZ27Be+xZ5oaf9ReH7BWEvqFw7eTNuwf8Vcy2l6he3A59twefZ5yWRLqbpLDAMPp+3142ltsbPEMLDC9vMJNqnE49v4q7D9WnhaB/VYfs89sJr250SntcVdXobL/SM01eOPFZ1LNq3EXV2dq6E/gIvePSmpgzpG2//mTG2LQ4q7wI0l/ha4qcT7I7qxxN83uKHEvwM3kvjXEd1M4qMNbiJxKDC9xFP3lenjI5pi4jmUt4nDG0wt8b3oJnFcYBbSeXezvxZ9v1EcGZhlecyhJ6i/Bv7c4ujAVApvXtlcE8cHJlLYuWteEicEplHYdIPKKYEpFNb9X2imWX9hNasvgcJ8yolMsXA/N3DlhYd5l+D6C0/z+9ZdOPsSXHvhpG+9FAvvfsZXqHB1FCpUGJ/CkcKKC/fLGPgPAAAAAAAAAAAAAAAAAAAAAAAAAGAN/gEHQw4UigZgSAAAAABJRU5ErkJggg=='},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},
                            { source : 'http://www.rangerwoodperiyar.com/images/joomlart/demo/default.jpg'},]
                        }
                    ]
                }
            }
        ]
    }
]