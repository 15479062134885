import { Fieldset } from "primeng/primeng";

export const htxExpandedForm = [
    {
        title: "_INFO",
        type: 'info',
        items: [
            { field: 'link',                    label: '_LINK',                 editable: false,    type: 'link',   editor: 'link'                                                              },
            { field: 'status',					label: '_STATUS', 				editable: false, 	type: 'text',	newBlocked: true							                                },
            { field: 'verified', 				label: '_VERIFIED', 			editable: false, 	type: 'text',	editor: 'readonly_toggle'													},
            { field: 'direction', 			    label: '_DIRECTION', 			editable: false, 	type: 'text' 																				},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'orderref', 				label: '_ORDERREF', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'infants', 				label: '_INFANTS', 			    editable: false, 	type: 'text',	editor: 'number'															},
            {   
                type    : 'info',
                label   : '_VEHICLE_INFO',
                
            },
            { field: 'vehicles',				label: '_VEHICLES_QTY', 		editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'vehicle',				 	label: '_VEHICLE', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'vehicle_mapped',			label: '_VEHICLE_MAPPED', 		editable: false, 	type: 'text',	autoFixIcon: 'wrench',  entityList: 'fleet', entityType: 'data', editor: 'autocomplete'		},
            {   
                type    : 'info', 
                label   : '_VEHICLE_ASSIGNED_INFO',

            },
            { field: 'vehicle_assigned_qty',	label: '_VEHICLES_ASSIGNED_QTY',editable: false,    type: 'text',   autoFixIcon: 'wrench',  original: 'false'	 },
            // { field: 'vehicle_assigned',		label: '_VEHICLE_ASSIGNED', 	editable: false, 	type: 'text',	autoFixIcon: 'wrench',  original: 'false',	entityList: 'fleet', entityType: 'data', editor: 'autocomplete'		},

            // { field: 'price', 					label: '_PRICE', 				editable: true, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 					},
            // { field: 'extras', 				label: '_EXTRAS', 				editable: false,	type: 'text' 																				},
            // { field: 'extras', 					label: '_EXTRAS', 				editable: true,	    type: 'text',   editor: 'textarea' 															},
            // { field: 'bikes', 					label: '_BIKES', 				editable: true,	    type: 'text',   editor: 'number' 															},
            // { field: 'surf', 					label: '_SURF', 				editable: true,	    type: 'text',   editor: 'number' 															},
            // { field: 'golf', 					label: '_GOLF', 				editable: true,	    type: 'text',   editor: 'number' 															},
            // { field: 'babychairs', 				label: '_BABYCHAIRS', 			editable: true,	    type: 'text',   editor: 'number' 															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				},
            // { field: 'providerNote', 			label: '_HTX_NOTE', 		    editable: true, 	type: 'text' 																				},
            // { field: 'customerNote', 			label: '_CUSTOMER_NOTE', 		editable: true, 	type: 'text' 																				},
            // { field: 'customerNote', 			label: '_CUSTOMER_NOTE', 		editable: true, 	type: 'text' 																				},
            // { field: 'childseatmissing', 	label: '_CHILD_SEAT_MISSING', 	editable: true, 	type: 'text',	editor: 'toggle'															}
        ]
    }
    , {
        title: "_ARRIVAL",
        type: 'arrival',
        items: [
            { field: 'arrival_Group', 				    	label: '_ARRIVAL_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'arrival_Vehicle', 					label: '_ARRIVAL_VEHICLE', 				editable: false, 	type: 'text' 								                                        	},
            { field: 'arrival_Driver', 					    label: '_ARRIVAL_DRIVER', 				editable: false, 	type: 'text' 	    							                                    	},
            { field: 'arrival_GatewayTo', 					label: '_AIRPORT_FROM', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 								},
            { field: 'arrival_From', 						label: '_AIRPORT_TO', 					editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 								},
            { field: 'arrival_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', renderer: 'flight' 			},
            // { field: 'arrival_Date', 					label: '_ARRIVAL_DATE', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight'								    },
            { field: 'arrival_Date_Calendar',				label: '_ARRIVAL_DATE', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'datetime'			},
            { field: 'arrival_Time_Calendar',				label: '_ARRIVAL_TIME', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'time'				    },
            { field: 'arrival_Zone', 						label: '_ZONE', 						editable: false, 	type: 'text'																		    },
            { field: 'arrival_To', 							label: '_AREA', 						editable: false, 	type: 'text', 	check: 'hotel', _entityList: 'areasList', _editor: 'autocomplete' 	    },
            // { field	: 'location', 						label:'_ACCOMMODATION',					editable: true,		type: 'text',	autoFixIcon: 'wrench',	check: 'hotel'								    },
            // { field	: 'addressInfo', 					label:'_ADDRESS', 						editable: true, 	type: 'text',	autoFixIcon: 'wrench',	check: 'hotel'								    },
            { field: 'arrival_AddressInfo', 				label: '_ADDRESS', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel' 								    },
            // { field: 'location', 					        label: '_ACCOMMODATION',				editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel', 	extra: ['field'] 			},
            { field: 'arrival_Location', 					label: '_ACCOMMODATION',				editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel', 	extra: ['field'] 			},
            // { field: 'arrival_Pickup_Date_Calendar',		label: '_ARRIVAL_PICKUP_DATE', 			editable: false, 	type: 'text', 	editor: 'datetime'			                                            },
            // { field: 'arrival_Pickup_Time_Calendar',	    label: '_ARRIVAL_PICKUP_TIME', 		    editable: false, 	type: 'text', 	editor: 'time'				                                            },
            { field: 'arrival_DriverNote', 		            label: '_DRIVER_NOTE', 			        editable: true, 	type: 'text' 																			},
            {   
                type    : 'info',
                label   : '_ARRIVAL_EXTRAS_INFO'
            },
            { field: 'arrival_extras_str', 					label: '_EXTRAS', 				        editable: true,	    type: 'text',   editor: 'textarea' 															},
            { field: 'arrival_bikes', 					    label: '_BIKES', 				editable: true,	    type: 'text',   editor: 'number' 															},
            { field: 'arrival_surf', 					    label: '_SURF', 				editable: true,	    type: 'text',   editor: 'number' 															},
            { field: 'arrival_golf', 					    label: '_GOLF', 				editable: true,	    type: 'text',   editor: 'number' 															},
            
            {   
                type    : 'info',
                label   : '_ARRIVAL_QUERIES_INFO'
            },        
            { field: 'arrival_flightnoquery', 				label: '_FLIGHT_NO_QUERY', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_wrongresort', 				label: '_WRONG_RESORT', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_missingaccommodation',		label: '_MISSING_ACCOMMODATION', 		editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            // { field: 'arrival_cruisershipname', 			label: '_CRUISER_SHIPNAME', 			editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_shuttletoprivateaddress', 	label: '_SHUTTLE_TO_PRIVATE_ADDRESS', 	editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_noshowarrival', 				label: '_NO_SHOW', 						editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            }
        ]
    }
    , {
        title: "_DEPARTURE",
        type: 'departure',
        items: [
            { field: 'departure_Group', 				    label: '_DEPARTURE_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'departure_Vehicle', 					label: '_DEPARTURE_VEHICLE', 			editable: false, 	type: 'text' 								                                        	},
            { field: 'departure_Driver', 				    label: '_DEPARTURE_DRIVER', 			editable: false, 	type: 'text' 	    							                                    	},
            
            { field: 'departure_Zone', 						label: '_ZONE', 						editable: false,	type: 'text',   _entityList: 'zonesList', _editor: 'autocomplete' 						},
            { field: 'departure_To', 						label: '_AREA',         				editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'flight' 									},
            { field: 'departure_AddressInfo', 				label: '_ADDRESS', 						editable: true,		type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Location', 					label: '_ACCOMMODATION', 				editable: true, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Pickup_Place',		        label: '_DEPARTURE_PICKUP_PLACE', 		editable: true, 	type: 'text', 	_editor: 'datetime'			                                            },
            // { field: 'departure_Pickup_Date_Calendar',		label: '_DEPARTURE_PICKUP_DATE', 		editable: true, 	type: 'text', 	editor: 'datetime'			                                            },
            // { field: 'departure_Pickup_Time_Calendar',		label: '_DEPARTURE_PICKUP_TIME', 		editable: true, 	type: 'text', 	editor: 'time'				                                            },
            
            { field: 'departure_From', 						label: '_AIRPORT_FROM', 				editable: false,	type: 'text', check: 'hotel', _entityList: 'areasList', 	_editor: 'autocomplete' 	},
            { field: 'departure_GatewayTo', 				label: '_AIRPORT_TO', 					editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight' 									},
            { field: 'departure_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		renderer: 'flight' 			},
            // { field: 'departure_Date', 					label: '_DEPARTURE_DATE',			 	editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight'									},
            { field: 'departure_Date_Calendar',				label: '_DEPARTURE_DATE', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'datetime'			},
            // { field: 'departure_Time',					label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight'									},
            { field: 'departure_PickupTime',		        label: '_DEPARTURE_PICKUP_TIME', 		editable: true, 	type: 'text', 			                                                                },
            { field: 'departure_Time_Calendar',				label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'time'				},
            { field: 'departure_DriverNote', 				label: '_DRIVER_NOTE', 					editable: true, 	type: 'text' 																			},
            {   
                type    : 'info',
                label   : '_DEPARTURE_EXTRAS_INFO'
            },
            { field: 'departure_extras_str', 				label: '_EXTRAS', 				        editable: true,	    type: 'text',   editor: 'textarea' 															},
            { field: 'departure_bikes', 					label: '_BIKES', 			        	editable: true,	    type: 'text',   editor: 'number' 															},
            { field: 'departure_surf', 					    label: '_SURF', 				        editable: true,	    type: 'text',   editor: 'number' 															},
            { field: 'departure_golf', 					    label: '_GOLF', 				        editable: true,	    type: 'text',   editor: 'number' 															},
            
            {   
                type    : 'info', 
                label   : '_DEPARTURE_QUERIES_INFO'                
            },
            { field: 'departure_flightnoquery', 			label: '_FLIGHT_NO_QUERY', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_wrongresort', 				label: '_WRONG_RESORT', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_missingaccommodation',		label: '_MISSING_ACCOMMODATION', 		editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            // { field: 'departure_cruisershipname', 		label: '_CRUISER_SHIPNAME', 			editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_shuttletoprivateaddress',	label: '_SHUTTLE_TO_PRIVATE_ADDRESS', 	editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_noshowdeparture', 			label: '_NO_SHOW', 						editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            }

        ]
    }
];

export const htxExpandedArrivalForm = [
{
        title: "_GENERAL_INFO",
        type: 'info',
        items: [
            { field: 'link',                    label: '_LINK',                 editable: false,    type: 'link',   editor: 'link'                                                              },
			{ field: 'status',					label: '_STATUS', 				editable: false, 	type: 'text',	newBlocked: true							                                },
            { field: 'verified', 			    label: '_VERIFIED', 			editable: false, 	type: 'text',	editor: 'status_toggle', original: 'false'					            	},            
            { field: 'direction', 			    label: '_DIRECTION', 			editable: false, 	type: 'text' 																				},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'orderref', 				label: '_ORDERREF', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'infants', 				label: '_INFANTS', 			    editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				},
            // { field: 'providerNote', 			label: '_HTX_NOTE', 		    editable: true, 	type: 'text' 																				},
        ]
    }
    , {
        title: "_ARRIVAL_INFO",
        type: 'arrival',
        items: [
		    { field: 'arrival_From', 						label: '_AIRPORT_TO', 					editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 								},
            { field: 'arrival_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', renderer: 'flight' 			},
        	{   field   	: 'arrival_GatewayInfo_alias', 		
                label   	: '_FLIGHT_ALIAS', 						
                editable	: true, 	
                type    	: 'alias',
                editor  	: 'alias',
                check_field	: 'arrival_GatewayInfo'
            },
            {   
                type    	: 'action', 
                editor  	: 'action',
                name    	: 'create_alias',
                label		: '_CREATE_ALIAS',
                check_field	: 'arrival_GatewayInfo'              
            },
		    { field: 'arrival_Date_Calendar',				label: '_ARRIVAL_DATE', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'datetime'			},
            { field: 'arrival_Time_Calendar',				label: '_ARRIVAL_TIME', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'time'				    },
        	{   
                type    : 'info',
                label   : '_ARRIVAL_EXTRAS_INFO'
            },
            { field: 'arrival_bikes', 					    label: '_BIKES', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_surf', 					    label: '_SURF', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_golf', 					    label: '_GOLF', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_extras_str', 					label: '_EXTRAS', 	            		editable: true,	    type: 'text',   editor: 'textarea' 														}							
        ]
    },
    {
        title   : "_ARRIVAL_TO_INFO",
        type    : 'arrival',
        items   : [
            { field: 'arrival_To', 							label: '_PROVIDER_RESORT', 				editable: false, 	type: 'text'    },
            { field: 'arrival_resort_tourinia', 			label: '_TOURINIA_RESORT', 				editable: false, 	type: 'text'    },
            { field       : 'arrival_municipality_dropdown', 				
              label       : '_TOURINIA_MUNICIPALITY',
              editable    : true, 	
              type        : 'text', 	
              check       : 'hotel', 
              entityList  : 'areasList', 
              editor      : 'autocomplete',
              onSelect    : 'assignZone'	    
            },
            { field: 'arrival_Zone', 						label: '_ZONE', 						editable: false, 	type: 'text'																		    },
			{ field: 'arrival_AddressInfo', 				label: '_ADDRESS', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel' 								    },
            { field: 'arrival_Location', 					label: '_ACCOMMODATION',				editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel', 	extra: ['field'] 			},
			
			{   
                type    : 'info',
                label   : '_GOOGLE_INFO'
            },
			{   
                type    	: 'button',
				editor		: 'button',
                label   	: '_GOOGLE_LODGING_SEARCH',
				action      : 'google_lodging_search',
				field       : 'arrival_Location',                				
			},
            {   field       : 'arrival_Location_Google', 			
                label       : '_ACCOMMODATION_GOOGLE', 		
                editable    : true,
                type        : 'text',
				editor		: '_google_lodging'
            },
            { field: 'arrival_Area_Google', 			    label: '_AREA_GOOGLE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'arrival_Address_Google', 				label: '_ADDRESS_GOOGLE', 		        editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'arrival_Latitude', 			        label: '_LATITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'arrival_Longitude', 			    	label: '_LONGITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            
			// {   
            //     type    : 'info',
            //     label   : '_ARRIVAL_SERVICE_INFO'
            // },
			// { field: 'arrival_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            // { field: 'arrival_VehicleType', 				label: '_ARRIVAL_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            // { field: 'arrival_Group', 				    	label: '_ARRIVAL_GROUP', 				editable: false, 	type: 'text'                                         									},
            // { field: 'arrival_Vehicle', 					label: '_ARRIVAL_VEHICLE', 				editable: false, 	type: 'text' 								                                        	},
            // { field: 'arrival_Driver', 					    label: '_ARRIVAL_DRIVER', 				editable: false, 	type: 'text' 	    							                                    	},        
            // { field: 'arrival_DriverNote', 		            label: '_DRIVER_NOTE', 			        editable: true, 	type: 'text' 																			}
         ]
    }
    , {
        title: "_ARRIVAL_SERVICE_INFO",
        type: 'arrival',
        items: [
			// {   
            //     type    : 'info',
            //     label   : '_ARRIVAL_SERVICE_INFO'
            // },
			{ field: 'arrival_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'arrival_VehicleType', 				label: '_ARRIVAL_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            { field: 'arrival_Group', 				    	label: '_ARRIVAL_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'arrival_Vehicle', 					label: '_ARRIVAL_VEHICLE', 				editable: false, 	type: 'text' 								                                        	},
            { field: 'arrival_Driver', 					    label: '_ARRIVAL_DRIVER', 				editable: false, 	type: 'text' 	    							                                    	},        
            { field: 'arrival_DriverNote', 		            label: '_DRIVER_NOTE', 			        editable: true, 	type: 'text' 																			},
			{   
                type    : 'info',
                label   : '_ARRIVAL_NOTIFICATIONS'
            },
			{ field: 'arrival_notification_pending', 		label: '_ARRIVAL_NOTIFICATION_PENDING', editable: false, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },			
            // {
            //     type    : 'info', 
            //     label   : '_ARRIVAL_QUERIES_INFO'                
            // },        
            { field: 'arrival_flightnoquery', 				label: '_FLIGHT_NO_QUERY', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_wrongresort', 				label: '_WRONG_RESORT', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_missingaccommodation',		label: '_MISSING_ACCOMMODATION', 		editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_shuttletoprivateaddress', 	label: '_SHUTTLE_TO_PRIVATE_ADDRESS', 	editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'arrival_noshowarrival', 				label: '_NO_SHOW', 						editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'providerNote', 			label: '_HTX_NOTE', 		    editable: true, 	type: 'text' 																				}         
        
        ]
    }
];

export const htxExpandedDepartureForm = [
    {
        title: "_GENERAL_INFO",
        type: 'info',
        items: [
            { field: 'link',                    label: '_LINK',                 editable: false,    type: 'link',   editor: 'link'                                                              },
            { field: 'status',					label: '_STATUS', 				editable: false, 	type: 'text',	newBlocked: true							                                },
            { field: 'verified', 			    label: '_VERIFIED', 			editable: false, 	type: 'text',	editor: 'status_toggle', original: 'false'					            	},            
            { field: 'direction', 			    label: '_DIRECTION', 			editable: false, 	type: 'text' 																				},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'orderref', 				label: '_ORDERREF', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'infants', 				label: '_INFANTS', 			    editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				},
        ]
    },
    {
        title: "_DEPARTURE_PICKUP_INFO",
        type: 'departure',
        items: [
			{ field: 'departure_From', 						label: '_PROVIDER_RESORT',				editable: false, 	type: 'text', 	check: 'hotel', _entityList: 'areasList', _editor: 'autocomplete' 	    },
            { field: 'departure_resort_tourinia', 			label: '_TOURINIA_RESORT', 				editable: false, 	type: 'text'    },
            { field       : 'departure_municipality_dropdown', 				
              label       : '_TOURINIA_MUNICIPALITY',
              editable    : true, 	
              type        : 'text', 	
              check       : 'hotel', 
              entityList  : 'areasList', 
              editor      : 'autocomplete',
              onSelect    : 'assignZone'	    
            },
            { field: 'departure_Zone', 						label: '_ZONE', 						editable: false,	type: 'text',   _entityList: 'zonesList', _editor: 'autocomplete' 						},
			{ field: 'departure_AddressInfo', 				label: '_ADDRESS', 						editable: true,		type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Location', 					label: '_ACCOMMODATION', 				editable: true, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            
			{   
                type    : 'info',
                label   : '_GOOGLE_INFO'
            },
			{   
                type    	: 'button',
				editor		: 'button',
                label   	: '_GOOGLE_SEARCH_LODGING',
				action      : 'lodging_search',
				field       : 'departure_Location',                				
			},
            {   
				field       : 'departure_Location_Google', 			
                label       : '_ACCOMMODATION_GOOGLE', 		
                editable    : false, 	
                type        : 'text',
				editor		: '_google_lodging'
            },
            { field: 'departure_Area_Google', 			    label: '_AREA_GOOGLE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Address_Google', 			label: '_ADDRESS_GOOGLE', 		        editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Latitude', 			        label: '_LATITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Longitude', 			    label: '_LONGITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},        
    		// {   
            //     type    : 'info',
            //     label   : '_DEPARTURE_SERVICE_INFO'
            // },
			// { field: 'departure_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            // { field: 'departure_VehicleType', 				label: '_DEPARTURE_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            // { field: 'departure_Group', 				    label: '_DEPARTURE_GROUP', 				editable: false, 	type: 'text'                                         									},
            // { field: 'departure_Vehicle', 					label: '_DEPARTURE_VEHICLE', 			editable: false, 	type: 'text' 								                                        	},
            // { field: 'departure_Driver', 				    label: '_DEPARTURE_DRIVER', 			editable: false, 	type: 'text' 	    							                                    	},
			// { field: 'departure_DriverNote', 				label: '_DRIVER_NOTE', 					editable: true, 	type: 'text' 																			}
        ]
    },
    {
        title: "_DEPARTURE_INFO",
        type: 'departure',
        items: [
			{ field: 'departure_To', 						label: '_AIRPORT_FROM',         		editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'flight' 									},            
            { field: 'departure_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		renderer: 'flight' 			},
            {   field   	: 'departure_GatewayInfo_alias', 		
                label   	: '_FLIGHT_ALIAS', 						
                editable	: true, 	
                type    	: 'alias',
                editor  	: 'alias',
                check_field	: 'departure_GatewayInfo'
            },
            {   
                type    	: 'action', 
                editor  	: 'action',
                name    	: 'create_alias',
                label		: '_CREATE_ALIAS',
                check_field	: 'departure_GatewayInfo'              
            },
            { field: 'departure_Time_Calendar',				label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'time'				},
			{ field: 'departure_Pickup_Place',		        label: '_DEPARTURE_PICKUP_PLACE', 		editable: true, 	type: 'text', 	_editor: 'datetime'			                                            },
            { field: 'departure_Date_Calendar',				label: '_DEPARTURE_DATE', 				editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'flight', 		editor: 'datetime'		},
            { field: 'departure_PickupTime',		        label: '_DEPARTURE_PICKUP_TIME', 		editable: true, 	type: 'text', 			                                                                },
	
			{   
                type    : 'info',
                label   : '_DEPARTURE_EXTRAS_INFO'
            },  
			

		    { field: 'departure_bikes', 					label: '_BIKES', 			        	editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_surf', 					    label: '_SURF', 				        editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_golf', 					    label: '_GOLF', 				        editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_extras_str', 				label: '_EXTRAS', 				        editable: true,	    type: 'text',   editor: 'textarea' 														},
        ]
    },
    {
        title: "_DEPARTURE_SERVICE_INFO",
		type: 'departure',
        items: [
			// {   
            //     type    : 'info',
            //     label   : '_DEPARTURE_SERVICE_INFO'
            // },
			{ field: 'departure_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'departure_VehicleType', 				label: '_DEPARTURE_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            { field: 'departure_Group', 				    label: '_DEPARTURE_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'departure_Vehicle', 					label: '_DEPARTURE_VEHICLE', 			editable: false, 	type: 'text' 								                                        	},
            { field: 'departure_Driver', 				    label: '_DEPARTURE_DRIVER', 			editable: false, 	type: 'text' 	    							                                    	},
			{ field: 'departure_DriverNote', 				label: '_DRIVER_NOTE', 					editable: true, 	type: 'text' 																			},
            
			{
                type    : 'info', 
                label   : '_DEPARTURE_QUERIES_INFO'                
            },
            { field: 'departure_notification_pending', 		label: '_DEPARTURE_NOTIFICATION_PENDING', editable: false, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },			
            { field: 'departure_flightnoquery', 			label: '_FLIGHT_NO_QUERY', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_wrongresort', 				label: '_WRONG_RESORT', 				editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_missingaccommodation',		label: '_MISSING_ACCOMMODATION', 		editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_shuttletoprivateaddress',	label: '_SHUTTLE_TO_PRIVATE_ADDRESS', 	editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'departure_noshowdeparture', 			label: '_NO_SHOW', 						editable: true, 	type: 'text',	editor: 'status_toggle', original: 'false'					            },
            { field: 'providerNote', 			            label: '_HTX_NOTE', 		    editable: true, 	type: 'text' 																				}         
        
        ]
    }
];

export const htxEmptyRow = {
	fixes								: [],
	errors								: [],
	newItem								: true,
	provider							: 1,
    status								: "PCON", 
    addressInfo         		        : "",
    adults              				: "",
    area                				: "",
    arrival_AddressInfo 				: "",
    arrival_Date        				: "",
    arrival_From        				: "",
    arrival_GatewayFrom 				: "unknown",
    arrival_GatewayInfo 				: "",
    arrival_GatewayTo   				: "",
    arrival_Location            		: "",
    arrival_StartingPointType   		: "",
    arrival_Time                		: "",
    arrival_To							: "",
    arrival_Zone						: "",
    bookingDate							: "",
    children							: "",
    company								: "",
    customer							: "",
    date								: "",
    direction							: "",
    infants								: "",
	location							: "",
    pax									: "",
    phone								: "",
    price								: 0,
	reference							: "",
    shared								: "",
    transporter_status					: "",
    vehicle								: "",
    vehicles							: "",
    verified							: "",
    zone								: "",
    last_addressInfo					: "",
    last_adults							: "",
    last_area							: "",
    last_arrival_AddressInfo			: "",
    last_arrival_Date					: "",
    last_arrival_From					: "",
    last_arrival_GatewayFrom			: "",
    last_arrival_GatewayInfo			: "",
    last_arrival_GatewayTo				: "",
    last_arrival_Location				: "",
    last_arrival_StartingPointType		: "",
    last_arrival_Time					: "",
    last_arrival_To						: "",
    last_arrival_Zone					: "",
    last_bookingDate					: "",
    last_children						: "",
    last_company						: "",
    last_customer						: "",
    last_date							: "",
    last_direction						: "",
    last_infants						: "",
    last_location						: "",
    last_newItem						: true,
    last_pax							: "",
    last_phone							: "",
    last_price							: 0,
    last_reference						: "",
    last_shared							: "",
    last_status							: "PCON",
    last_transporter_status				: "",
    last_vehicle						: "",
    last_vehicles						: "",
    last_verified						: "",
    last_zone							: "",
	original_addressInfo				: "",
    original_adults						: "",
    original_area						: "",
    original_arrival_AddressInfo		: "",
    original_arrival_Date				: "",
    original_arrival_From				: "",
    original_arrival_GatewayFrom		: "",
    original_arrival_GatewayInfo		: "",
    original_arrival_GatewayTo			: "",
    original_arrival_Location			: "",
    original_arrival_StartingPointType	: "",
    original_arrival_Time				: "",
    original_arrival_To					: "",
    original_arrival_Zone				: "",
    original_bookingDate				: "",
    original_children					: "",
    original_company					: "",
    original_customer					: "",
    original_date						: "",
    original_direction					: "",
    original_infants					: "",
    original_location					: "",
    original_pax						: "",
    original_phone						: "",
    original_price						: 0,
    original_reference					: "",
    original_shared						: "",
    original_status						: "",
    original_statusToken				: "",
    original_vehicle					: "",
    original_vehicles					: "",
    original_verified					: ""
}